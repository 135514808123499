import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { headerHeight } from '@constants/styles';
import { ColorsPalette } from '@themes/colors';
import { defaultTransition } from '@themes/transition';
import { TypographyCollection } from '@themes/typography';

export default makeStyles<Theme>((theme) => ({
  appBar: {
    '& $iconsContainer': {
      '& $icon': {
        padding: '0 10px',
        ...{
          '& $label': {
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '0.4rem',
          },
          '& $labelIcon': {
            '& svg': {
              marginLeft: 4,
            },
            marginRight: -4,
          },
          '&$iconAccount': {
            '&:hover': {
              '& $label': {
                color: ColorsPalette.hoverActionCTA,
              },
              '& > svg path': {
                fill: ColorsPalette.hoverActionCTA,
              },
              backgroundColor: ColorsPalette.neutral1,
            },
            '&:hover &$authorized': {
              backgroundColor: ColorsPalette.coralPinkLight,
            },
          },
          '&$iconAction': {
            '&:hover': {
              '& $label': {
                color: ColorsPalette.hoverActionCTA,
              },
              '& > svg path': {
                fill: ColorsPalette.hoverActionCTA,
              },
              backgroundColor: ColorsPalette.neutral1,
            },
            '&:hover &$authorized': {
              backgroundColor: ColorsPalette.coralPinkLight,
            },
          },
          alignItems: 'center',
          borderRadius: 12,
          cursor: 'pointer',
          display: 'flex',
          flexDirection: 'column',
          height: '4rem',
          justifyContent: 'center',
          minWidth: 80,
          position: 'relative',
        },
        ...TypographyCollection.link.bold.xs,
      },
      alignItems: 'center',
      display: 'flex',
      height: 64,
      justifyContent: 'flex-end',
      marginLeft: 'auto',
      marginTop: 17,
      position: 'relative',
    },
    '& $logoContainer': {
      '& $logo': {
        cursor: 'pointer',
        width: '100%',
      },
      display: 'flex',
      flex: '1 1 100%',
      marginRight: 70,
      maxWidth: 146,
      paddingTop: 16,
      width: '100%',
    },
    '& $searchMenuContainer': {
      display: 'flex',
      flex: '1 1 0',
      flexDirection: 'column',
      height: '100%',
      marginRight: 10,
      maxWidth: 524,
      width: '100%',
      [theme.breakpoints.between('sm', 'lg')]: {
        maxWidth: 470,
      },
    },
    alignItems: 'center',
    backgroundColor: ColorsPalette.neutral2,
    display: 'flex',
    // flex: '0 0 100%',
    flexDirection: 'row',
    height: headerHeight,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      paddingLeft: '80px',
      paddingRight: '53px',
    },
    [theme.breakpoints.between('sm', 'lg')]: {
      paddingLeft: '30px',
      paddingRight: '3px',
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: '0',
      paddingRight: '0',
    },
  },
  authorized: {},
  countBadge: {
    alignItems: 'center',
    backgroundColor: ColorsPalette.coralPink,
    border: '2px solid ' + ColorsPalette.neutral2,
    borderRadius: '50%',
    color: ColorsPalette.neutral2,
    display: 'flex',
    fontSize: 9,
    height: 18,
    justifyContent: 'center',
    position: 'absolute',
    right: 42,
    top: 21,
    width: 18,
    [theme.breakpoints.down('md')]: {
      right: 24,
      top: 24,
    },
  },
  icon: {},
  iconAccount: {},
  iconAction: {},
  iconsContainer: {},
  label: {},
  labelIcon: {},
  logo: {},
  logoContainer: {},
  mobileAppBar: {
    alignItems: 'center',
    display: 'flex',
    height: 48,
    justifyContent: 'flex-start',
    width: '100%',
  },
  mobileAppBarIcon: {
    ...{
      alignItems: 'center',
      cursor: 'pointer',
      display: 'flex',
      flex: '1 1 48px',
      justifyContent: 'center',
      maxHeight: 48,
      maxWidth: 48,
      width: '25%',
    },
    ...TypographyCollection.link.bold.xs,
  },
  mobileAppBarIconRow: {
    '& svg': {
      transition: defaultTransition,
    },
    display: 'flex',
    flex: '1 1 192px',
    flexDirection: 'row',
    maxWidth: '192px',
    transition: defaultTransition,
  },
  mobileAppBarLogoContainer: {
    '& $logo': {
      width: '100%',
    },
    alignItems: 'center',
    display: 'flex',
    flex: '1 1 120px',
    height: '3rem',
    marginRight: 'auto',
    maxWidth: '120px',
    padding: '0 9px 0 7px',
    width: '100%',
  },
  mobileAppBarLogoLink: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    padding: '0 9px 0 7px',
  },
  mobileAppBarMain: {
    '& $iconsContainer': {
      '& $icon': {
        ...{
          alignItems: 'center',
          cursor: 'pointer',
          display: 'flex',
          flexDirection: 'column',
          height: '3rem',
          justifyContent: 'center',
          width: '3rem',
        },
        ...TypographyCollection.link.bold.xs,
      },
      display: 'flex',
      justifyContent: 'flex-end',
      width: '70%',
    },
    alignItems: 'center',
    display: 'flex',
    height: 48,
    justifyContent: 'flex-start',
    transition: defaultTransition,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      '& $logoContainer': {
        '& $logo': {
          width: '5.5rem',
        },
      },
    },
  },
  mobileAppBarMainHide: {
    '& $mobileAppBarIconRow': {
      '& svg': {
        width: 0,
      },
      width: 0,
    },
    '& $mobileAppBarLogoContainer': {
      padding: 0,
    },
    opacity: 0,
    overflow: 'hidden',
    width: 0,
  },
  mobileAppBarSearch: {
    '& button': {
      height: 46,
    },
    '& input': {
      height: 46,
    },
    alignItems: 'center',
    display: 'flex',
    height: 48,
    justifyContent: 'flex-start',
    padding: '2px 10px 0 10px',
    transition: defaultTransition,
    width: '100%',
    [theme.breakpoints.down(330)]: {
      padding: '2px 7px 0 20px',
    },
  },
  mobileAppBarSearchHide: {
    '& input': {
      display: 'none !important',
      padding: 0,
      width: 0,
    },
    opacity: 0,
    padding: 0,
    width: 0,
    zIndex: -1,
  },
  searchMenuContainer: {},
}));
