import React from 'react';

const DenimDreamLogo: React.FunctionComponent<{ className?: string; }> = ({ className}) => {
  return (
    <svg className={className} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
         viewBox="0 0 477.7 87.4" xmlSpace="preserve">
      <g>
	<path className="denimdream-logo" style={{fill: '#232323' }} d="M0,0h58.1c23,0,41.2,20.2,41.2,43.3c0,44-34.3,44.1-67.3,44.1V67.2h17.3c16.4,0,24.6-9.2,24.6-23.7
		c0-14.5-9.1-23.5-23.1-23.5H29.1V52H4.2V6.2h0c0-0.1,0-0.2,0-0.3c0-2-1.6-3.6-3.6-3.6c-0.2,0-0.4,0-0.7,0.1V0z" />
        <path className="denimdream-logo" d="M143.8,35.5c-2.7-2.3-5.6-3.2-9.4-3.2c-3.9,0-7.6,1.4-10.4,4c-3.3,3-5.2,7.3-5.2,11.8c0,4.8,2,9.3,5.7,12.5
		c2.6,2.3,6.1,3.6,9.9,3.6c3.6,0,6.6-1,9.4-3.3v2.5h6.9V23.3h-6.9V35.5z M143.5,48.3c0,5-3.8,8.8-8.3,8.8c-5.4,0-9.1-3.9-9.1-8.9
		c0-4.8,3.9-9,8.8-9C139.8,39.3,143.5,43.2,143.5,48.3 M162.1,50.4h24.2c0-0.5,0.1-1,0.1-1.5c0-4-0.8-7.2-2.9-9.9
		c-3.2-4.3-7.9-6.8-13.1-6.8c-4.2,0-8.2,1.8-11.3,5.1c-2.8,3-4.3,6.8-4.3,11c0,4.1,1.6,8.2,4.5,11.2c3,3.2,6.9,4.7,11.3,4.7
		c6.7,0,12.1-3.8,14.6-10.4h-7.6c-1.5,2.4-4.1,3.8-7.2,3.8C165.7,57.5,162.5,54.8,162.1,50.4 M178.7,44.8h-16.5
		c1.1-3.8,4.3-6.2,8.1-6.2C174.6,38.7,177.6,40.8,178.7,44.8 M197.6,33.1h-6.9v30.3h6.8v-13c0-7.3,1.5-11,6.6-11s6.3,3.4,6.3,10.6
		v13.4h7v-16c0-3.9-0.2-7.1-2-9.9c-2.1-3.3-5.4-5-9.7-5c-3.4,0-6,1.3-8.1,4.1V33.1z M223.6,63.3h6.9V33.1h-6.9V63.3z M243.6,33.1
		h-6.9v30.3h6.8v-13c0-7.3,1.5-11,6.6-11c5.2,0,6.3,3.4,6.3,10.6v13.4h7v-13c0-7.3,1.4-11,6.5-11c5.1,0,6.1,3.4,6.1,10.6v13.4h6.9
		V46c0-8.8-4.2-13.5-11.5-13.5c-3.7,0-6.6,1.8-9,5.4c-2.5-3.7-5.9-5.4-10.7-5.4c-3.4,0-6,1.3-8.1,4.1V33.1z" />
        <path className="denimdream-logo" d="M330.8,48.3c0-2.6-0.8-4.8-2.5-6.5c-1.6-1.7-3.7-2.6-6.2-2.6c-2.4,0-4.5,0.9-6.2,2.6c-1.7,1.8-2.6,3.9-2.6,6.3
		c0,2.6,0.9,4.7,2.6,6.4c1.7,1.7,3.9,2.5,6.5,2.5c2.3,0,4.3-0.9,5.9-2.6C330,52.9,330.8,50.8,330.8,48.3 M331.1,35.5V23.2h6.9v40.1
		h-6.9v-2.5c-1.4,1.1-2.9,1.9-4.4,2.5c-1.5,0.5-3.2,0.8-5,0.8c-1.9,0-3.7-0.3-5.4-0.9c-1.7-0.6-3.2-1.5-4.6-2.6
		c-1.8-1.6-3.2-3.5-4.2-5.6c-1-2.2-1.5-4.4-1.5-6.9c0-2.2,0.5-4.4,1.4-6.5c0.9-2.1,2.2-3.8,3.8-5.3c1.4-1.3,3-2.3,4.7-3
		c1.8-0.7,3.7-1,5.7-1c1.9,0,3.7,0.3,5.2,0.8C328.4,33.6,329.8,34.4,331.1,35.5 M358.1,39.2c-2.7,0.2-4.6,0.9-5.7,2.2
		c-1.1,1.2-1.6,3.4-1.6,6.6v15.5h-7.1V33h6.6v3.5c1.1-1.4,2.2-2.4,3.5-3c1.3-0.6,2.7-1,4.3-1V39.2z M383.1,44.8
		c-0.6-2-1.6-3.6-3-4.6c-1.4-1.1-3.2-1.6-5.4-1.6c-1.9,0-3.6,0.6-5,1.7c-1.5,1.1-2.5,2.6-3,4.5H383.1z M366.4,50.4
		c0.2,2.2,1.1,3.9,2.6,5.2c1.5,1.3,3.4,1.9,5.8,1.9c1.6,0,3-0.3,4.3-1c1.3-0.7,2.3-1.6,3-2.8h7.6c-1.3,3.3-3.2,5.9-5.8,7.7
		c-2.6,1.8-5.5,2.7-8.9,2.7c-2.3,0-4.3-0.4-6.2-1.2c-1.9-0.8-3.6-2-5.1-3.5c-1.4-1.5-2.5-3.2-3.3-5.2c-0.8-2-1.2-4-1.2-6
		c0-2.1,0.4-4.1,1.1-6c0.7-1.9,1.8-3.5,3.2-5c1.5-1.6,3.3-2.9,5.2-3.8c1.9-0.9,3.9-1.3,6-1.3c2.6,0,5.1,0.6,7.4,1.8
		c2.3,1.2,4.2,2.9,5.8,5c1,1.3,1.7,2.8,2.2,4.4c0.5,1.6,0.7,3.5,0.7,5.6c0,0.2,0,0.4,0,0.8c0,0.3,0,0.6,0,0.8H366.4z M400.5,48
		c0,2.5,0.8,4.7,2.5,6.4c1.7,1.7,3.8,2.6,6.2,2.6c2.2,0,4.2-0.9,5.9-2.6c1.7-1.7,2.5-3.8,2.5-6.1c0-2.5-0.8-4.6-2.5-6.3
		c-1.7-1.8-3.7-2.7-6-2.7c-2.4,0-4.4,0.8-6.1,2.5C401.3,43.5,400.5,45.5,400.5,48 M418.4,63.4v-3.7c-0.8,1.5-2,2.6-3.6,3.3
		c-1.5,0.7-3.5,1.1-5.9,1.1c-4.5,0-8.2-1.5-11.2-4.6c-3-3.1-4.5-6.9-4.5-11.4c0-2.1,0.4-4.2,1.1-6.1c0.8-1.9,1.8-3.6,3.2-5
		c1.5-1.6,3.2-2.8,5-3.5c1.8-0.8,3.9-1.1,6.2-1.1c2.2,0,4.1,0.4,5.7,1.1c1.6,0.7,2.9,1.8,3.8,3.3V33h6.9v30.3H418.4z M438.4,33v3.4
		c1-1.4,2.2-2.4,3.5-3.1c1.3-0.7,2.8-1,4.6-1c2.4,0,4.5,0.4,6.3,1.3c1.7,0.9,3.2,2.2,4.5,4c1.2-1.8,2.5-3.1,4-4
		c1.5-0.9,3.1-1.3,4.9-1.3c3.7,0,6.6,1.2,8.5,3.5c2,2.3,3,5.7,3,10v17.4h-6.9V49.9c0-4.1-0.5-6.9-1.4-8.4c-0.9-1.5-2.5-2.2-4.7-2.2
		c-2.3,0-4,0.8-5,2.5c-1,1.7-1.5,4.5-1.5,8.6v13h-7V49.9c0-4.1-0.5-6.9-1.4-8.4c-0.9-1.5-2.5-2.2-4.9-2.2c-2.4,0-4,0.8-5.1,2.5
		c-1,1.7-1.5,4.5-1.5,8.6v13h-6.8V33H438.4z" />
        <path className="denimdream-logo" d="M227.5,20.6c2.3,0,4.2,1.9,4.2,4.2c0,2.3-1.9,4.2-4.2,4.2c-2.3,0-4.2-1.9-4.2-4.2
		C223.3,22.5,225.1,20.6,227.5,20.6" />
</g>
</svg>
  );
};

export default DenimDreamLogo;
