import React from 'react';

const DreamCafeLogo: React.FunctionComponent<{ className?: string; }> = ({className}) => {
  return (
      <svg id="MaileBeautyLogo" className={className}  xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 3695.17 1359.14">
        <defs>
          <style>
            {`
            .cls-1 {
            stroke - width: 2px;
          }

            .cls-1, .cls-2, .cls-3, .cls-4 {
            stroke: #000;
            stroke-miterlimit: 10;
          }

            .cls-3 {
            stroke - width: 3px;
          }

            .cls-4 {
            stroke - width: 13px;
          }
          `}
          </style>
        </defs>
        <g>
          <g id="Layer_1">
            <g id="DreamCafe_Grill_x26_Pizza">
              <g id="DreamCafe">
                <g>
                  <g>
                    <g>
                      <path className="cls-2"
                            d="M2858.06,672.67c-40.56,22.32-99.97,48.37-148.07,48.37-32.06,0-44.33-24.18-50.93-51.16-18.86,13.95-34.89,28.84-52.81,40-13.21,9.3-58.47,37.21-73.56,37.21-7.54,0-17.92-3.72-23.58-7.44-11.32-8.37-15.09-21.4-10.38-37.21,16.03-44.65,46.21-73.48,91.48-88.37,5.66-1.86,10.37-2.79,15.09-2.79,13.2,0,25.46,6.51,35.84,16.74,3.77,3.72,9.43,7.44,14.14,7.44,16.98,0,32.06,11.16,30.18,27.91-3.77,27.91,1.88,37.21,17.92,37.21,6.6,0,14.14-.93,24.52-3.72,36.78-9.3,71.67-24.18,107.51-37.21,4.72-1.86,9.43-6.51,14.15-5.58,4.71,0,10.37,3.72,14.14,7.44.94.93-2.83,9.3-5.66,11.16ZM2630.77,642.9c-4.71-3.72-10.38-6.51-16.03-6.51-33.95,4.65-77.33,46.51-85.82,81.86,2.83-.93,6.6-.93,8.49-1.86,30.18-20.47,60.36-40.93,90.53-62.33,2.83-1.86,3.77-10.23,2.83-11.16Z" />
                      <path className="cls-2"
                            d="M2942.92,716.39c-6.6,1.86-13.2,3.72-21.69,6.51,2.83,2.79,2.83,4.65,4.72,5.58,36.78,23.25,37.73,71.63,21.69,101.39-18.86,34.42-68.85,93.95-114.11,93.95-21.69,0-36.78-16.74-35.84-44.65.94-40.93,6.6-84.65,17.92-124.65-2.83-.93-4.72-1.86-5.66-3.72-5.66-5.58-13.2-10.23-15.09-16.74-.94-3.72,7.54-12.09,14.15-14.89,15.09-5.58,20.75-16.74,25.46-30.69,39.61-143.25,106.57-275.34,179.19-404.64,7.54-11.16,16.03-23.26,25.46-32.56,7.54-7.44,16.03-10.23,22.63-10.23,12.26,0,21.69,8.37,24.52,25.12,2.83,16.74,1.89,36.28-1.88,52.09-33.01,127.44-89.6,241.85-188.62,333.01-30.18,26.98-33.95,33.49-35.83,59.53,7.54,0,15.09,0,21.69-1.86,82.99-18.6,165.99-37.21,249.92-55.81,5.66-.93,13.21,4.65,19.81,7.44-4.72,3.72-8.49,10.23-14.15,11.16-65.07,15.81-130.14,29.76-194.28,44.65ZM2888.22,734.99c-6.6,0-14.14.93-20.75,1.86-14.15,3.72-21.69,10.23-24.52,25.12-3.77,21.4-9.43,41.86-13.21,63.26-3.77,24.18-4.71,48.37-7.54,77.21,53.76-22.33,87.71-55.82,106.57-104.18,6.6-16.74,8.49-36.28-5.66-50.23-10.38-10.23-22.63-13.03-34.89-13.03ZM2886.34,631.74c97.14-105.12,161.27-224.18,182.01-363.71-36.78,19.53-183.9,316.27-182.01,363.71Z" />
                      <path className="cls-2"
                            d="M3544.59,659.64c-2.83,4.65-5.66,12.09-10.37,13.96-39.61,13.02-78.28,27.9-118.83,37.21-52.81,12.09-147.12,39.07-218.79,39.07-17.92,0-34.89-1.86-49.04-5.58-33.96-8.37-46.21-34.42-42.44-68.84.94-13.95,10.38-19.53,16.98-26.97,22.64-27.91,69.79-64.19,107.52-64.19,11.32,0,20.75,6.51,28.29,17.67,8.49,13.03,2.83,25.12-5.66,34.42-30.18,29.77-62.24,49.3-106.57,49.3-9.43,0-16.03,0-18.86,11.16-1.89,11.16,0,19.54,10.38,22.32,18.86,5.58,39.61,7.44,61.3,7.44,64.13,0,133.92-20.46,181.07-30.69,49.04-12.09,97.14-28.84,146.18-42.79,5.66-.93,13.2,3.72,18.86,6.51ZM3148.49,666.16c37.73-6.52,66.96-25.12,93.37-56.75-31.13,0-80.16,27.91-93.37,56.75Z" />
                    </g>
                    <path className="cls-2"
                          d="M2540.91,652.23c-.76.1-1.48.37-2.11.79-5.19,3.51-10.37,7.04-15.47,10.69-1.36.98-2.6-.93-3.95-.27.46-.65-5.15,7.51-5.63,7.85-70.36,71.45-153.59,139.09-212.63,131.62-77.86-9.81-120.68-105.88-14.59-311.35,63.21-122.42,197.17-248.66,269.55-236.07,33.02,5.74,39.03,22.6,39.2,22.94,0,0,3.66,6.43,3.37,13.68-.53,13.15-4.97,25.32-36.03,46.94-3.72,2.59-2.25,8.21,2.3,8.8l5.92.77c5.15.67,10.38.21,15.28-1.46,12.36-4.21,34.5-15.21,43.77-41.17,1.76-4.93,3.58-17.82,2.59-25.4-8.34-64-77.21-64.91-114.23-58.15-59.7,10.89-202.01,100.86-295.44,279.65-78.12,149.5-70.51,241.15-17.74,284.48,44.99,36.79,121.98,60.5,191.19,25.35,55.6-28.37,103.35-71.15,161.94-140.77,7.12-8.46-.15-21.01-11.4-19.63-1.97.24-3.94.49-5.9.74Z" />
                  </g>
                  <g>
                    <path className="cls-3"
                          d="M1226.34,594.95c10.42,0,16.84,6.82,21.65,19.6h-6.41c-32.07,0-53.72,22.16-76.17,40.91-32.87,26.42-52.12,63.92-68.15,102.27-2.41,5.11-4.81,13.64-9.62,13.64-.8,0-2.41-.85-4.01-1.71-6.41-2.56-13.63-12.78-12.83-18.75,9.62-46.87,20.04-92.9,30.47-139.77,20.85,3.41,26.46,11.93,20.04,28.98-3.21,9.37-5.61,18.75-6.41,28.98,12.83-11.08,24.05-23.86,37.68-33.24,13.63-10.23,57.73-40.91,73.77-40.91Z" />
                    <path className="cls-3"
                          d="M1543.69,687.89c2.86-4.43,5.77-8.84,8.59-13.3-30.14,9.96-60.08,20.29-90.45,28.36-40.09,9.37-99.42,28.12-153.95,28.12-18.44,0-36.08-1.71-52.12-6.82-8.82-2.56-10.42-10.23-8.82-20.45,2.41-10.23,8.02-10.23,16.04-10.23,37.68,0,64.95-17.9,90.6-45.17,7.22-8.52,12.03-19.6,4.81-31.53-6.41-10.23-14.43-16.19-24.05-16.19-32.07,0-72.16,33.24-91.4,58.81-5.61,6.82-13.63,11.93-14.43,24.72-3.21,31.53,7.22,55.4,36.08,63.07,12.03,3.41,26.46,5.11,41.69,5.11,60.94,0,141.12-24.72,186.02-35.79,13.44-3.32,26.64-7.43,39.74-11.9,3.72-5.69,7.58-11.32,11.65-16.79ZM1344.77,623.69c-22.45,28.98-47.31,46.02-79.38,51.99,11.23-26.42,52.92-51.99,79.38-51.99Z" />
                    <path className="cls-3"
                          d="M1807.23,677.74c-34.48,20.45-84.99,44.32-125.88,44.32-27.26,0-37.68-22.16-43.3-46.87-16.04,12.78-29.67,26.42-44.9,36.65-11.23,8.52-49.71,34.09-62.54,34.09-6.41,0-15.23-3.41-20.04-6.82-9.62-7.67-12.83-19.6-8.82-34.09,13.63-40.91,39.29-67.33,77.78-80.96,4.81-1.7,8.82-2.56,12.83-2.56,11.22,0,21.65,5.97,30.47,15.34,3.21,3.41,8.02,6.82,12.03,6.82,14.43,0,27.26,10.23,25.66,25.57-3.21,25.57,1.6,34.09,15.23,34.09,5.61,0,12.03-.85,20.85-3.41,31.27-8.52,60.94-22.16,91.4-34.09,4.01-1.7,8.02-5.97,12.03-5.11,4.01,0,8.82,3.41,12.03,6.82.8.85-2.41,8.52-4.81,10.23ZM1613.99,650.47c-4.01-3.41-8.82-5.97-13.63-5.97-28.87,4.26-65.75,42.61-72.96,75,2.41-.85,5.61-.85,7.22-1.7,25.66-18.75,51.32-37.5,76.97-57.1,2.41-1.7,3.21-9.37,2.41-10.23Z" />
                    <path className="cls-3"
                          d="M2178.01,680.85c-17.21,7.6-34.39,15-51.8,21.61-12.42,4.26-25.58,5.97-38,5.97-17.54,0-23.38-8.52-27.04-28.98-1.46-11.93-2.19-24.72-5.12-36.65-4.38-19.6-14.62-31.53-28.5-31.53-16.81,0-56.27,29.83-70.15,40.91-19,14.49-36.54,31.53-57.73,49.43.73-8.52,1.46-14.49,1.46-20.45,0-12.78.73-25.57-1.46-37.5-2.92-21.31-13.88-34.09-29.23-34.09-5.12,0-10.23.85-16.08,4.26-16.08,9.37-31.42,21.31-47.5,32.38-2.92,1.71-5.85,3.41-8.04,5.11,1.46-6.82,5.85-10.23,7.31-15.34.73-4.26.73-12.78-2.19-15.34-2.19-1.7-5.85-2.56-8.77-2.56s-4.38.85-5.12,1.7c-20.46,33.24-40.19,67.33-58.46,101.42-2.19,4.26.73,15.34,4.38,19.6.73,1.71,3.65,2.56,5.85,2.56,3.65,0,7.31-1.7,8.77-3.41,11.69-10.23,21.19-22.16,31.42-34.09,16.08-20.45,70.88-76.7,95-76.7,5.12,0,6.58,5.11,6.58,18.75,0,12.78-.73,25.57-2.19,38.35-1.46,15.34-1.46,31.53,11.69,39.2,2.92,1.71,5.12,2.56,7.31,2.56,8.77,0,16.08-9.37,23.38-16.19,27.04-23.01,54.07-47.73,83.3-68.18,13.88-9.37,21.92-14.49,26.31-14.49,7.31,0,7.31,11.08,10.23,35.79,4.38,40.91,24.11,63.07,56.27,63.07,14.62,0,29.96-5.11,43.84-11.08,12.06-5.28,24-10.7,35.86-16.21-.77-6.62-1.35-13.25-1.6-19.89Z" />
                  </g>
                  <path className="cls-4"
                        d="M580.84,770.27c-5.63,32.24-14.84,64.65-.14,100.89-.67,1.03-2.05,2.1-2.72,3.13-3.78-3.81-8.9-5.56-11.29-10.44-10.1-15.49-12.45-34.36-11.22-53.39.28-9,.57-18.01,3.1-25.12,5.34-23.23,5.1-43.2-10.12-60.44-4.59-5.77-5.13-16.73-7.66-24.6,2.06-2.1,3.39-4.16,5.45-6.26,5.6,11.71,11.2,23.43,16.8,35.14,1.34-2.06,3.39-4.16,4.73-6.22,7.21-29.32,13.7-58.6,20.86-88.91,23.34-97.02,58.59-185.63,95.32-273.32,2.58-6.12,5.16-12.24,8.55-16.4,5.55-4.26,6.98-4.33,12.72-4.61,4.4,1.78,7.61,8.62,10.1,15.49.1,1.99-4.68,7.22-7.36,11.34-1.96,4.09-4.01,6.19-5.97,10.28-44.18,112.02-86.93,223.96-112.55,348.07-.43,6.01-1.62,11.07-3.39,19.14,11.77,5.42,22.91,12.87,33.82,15.34,40.8,11.01,80.54,15.07,119.28,13.2,90.39-4.38,174.73-44.43,251.4-124.06,24.62-26.16,44.54-60.09,57.67-100.68,23.26-69.05,13.09-130.49-29.69-182.35-68.81-84.56-184.51-112.92-272.03-108.67-155.66,7.55-310.73,72-444.29,179.36-17.31,13.82-33.7,31.6-50.96,46.42-5.45,6.26-11.71,10.56-16.44,16.78-6.6-2.68-8.47-11.58.37-21.99,12.9-15.61,26.53-31.25,41.01-43.94,141.45-122.73,294.79-193.09,461.93-201.19,20.8-1.01,41.65-1.02,63.27-.07,101.63,5.06,251.49,70.71,273,232.48,7.63,53.57-3.78,100.07-23.27,142.97-61.82,133.85-196.27,193.31-306.74,198.66-33,1.6-66.24-1.78-99.72-10.15-21.09-4.97-41.81-16.95-63.81-25.87Z" />
                </g>
                <path className="cls-2"
                      d="M3314.79,502.98c5.3-.86,10.53-1.51,10.52,1.51,0,1.94-.68,2.42-1.88,3.36-8.22,6.37-38.39,33.09-45.77,44.84-2.01,3.2-5.47,5.26-9.25,5.33-7.43.15-15.59-4.04-15.34-12.86.39-11.82,55.13-41.11,61.73-42.18Z" />
              </g>
              <g id="Grill_x26_Pizza">
                <path className="cls-1"
                      d="M1287.63,1057.07c-4.44,2.89-9.23,5.42-14.37,7.59-5.14,2.17-10.67,3.87-16.59,5.09-5.92,1.23-12.12,1.84-18.6,1.84-11.55,0-22.05-1.99-31.49-5.96-9.44-3.97-17.51-9.46-24.2-16.47-6.69-7.01-11.87-15.28-15.53-24.82-3.67-9.54-5.49-19.86-5.49-30.99s1.87-20.81,5.6-30.34c3.73-9.53,8.98-17.88,15.74-25.03,6.76-7.15,14.83-12.82,24.2-17.01,9.37-4.19,19.62-6.28,30.75-6.28,6.48,0,12.36.47,17.65,1.41,5.28.94,10.18,2.28,14.69,4.01,4.51,1.73,8.77,3.87,12.79,6.39,4.02,2.53,7.93,5.45,11.73,8.78l-16.48,20.16c-2.82-2.46-5.71-4.69-8.66-6.72-2.96-2.02-6.06-3.72-9.3-5.09-3.24-1.37-6.8-2.45-10.67-3.25-3.88-.79-8.14-1.19-12.79-1.19-6.76,0-13.07,1.41-18.91,4.24-5.85,2.82-10.92,6.69-15.22,11.62-4.3,4.92-7.68,10.64-10.14,17.16-2.47,6.52-3.7,13.46-3.7,20.85,0,7.82,1.2,15.06,3.59,21.72,2.39,6.66,5.81,12.42,10.25,17.26,4.44,4.85,9.72,8.65,15.85,11.4,6.13,2.75,13,4.12,20.61,4.12,7.04,0,13.63-1.05,19.76-3.16,6.13-2.11,11.45-4.9,15.96-8.39v-28.55h-37.83v-23.19h62.98v63.72c-3.67,3.18-7.71,6.21-12.15,9.1Z" />
                <path className="cls-1"
                      d="M1425.32,1068.99l-36.46-52.88h-32.65v52.88h-25.78v-151.71h65.94c8.45,0,16.1,1.12,22.93,3.36,6.83,2.24,12.61,5.42,17.33,9.54,4.72,4.12,8.35,9.18,10.88,15.17,2.54,6,3.8,12.68,3.8,20.05,0,6.36-.88,12.03-2.64,17.01-1.76,4.98-4.19,9.39-7.29,13.22-3.1,3.83-6.8,7.08-11.1,9.75-4.3,2.68-9.05,4.73-14.26,6.18l39.94,57.43h-30.64ZM1416.84,947.76c-5.37-4.28-12.93-6.42-22.68-6.42h-37.95v51.15h38.16c9.33,0,16.75-2.32,22.26-6.96,5.51-4.64,8.27-10.88,8.27-18.72,0-8.41-2.69-14.76-8.06-19.04Z" />
                <path className="cls-1" d="M1507.32,917.28v151.71h-25.78v-151.71h25.78Z" />
                <path className="cls-1" d="M1543.88,917.28h25.78v127.65h77.77v24.06h-103.56v-151.71Z" />
                <path className="cls-1" d="M1671.52,917.28h25.78v127.65h77.77v24.06h-103.56v-151.71Z" />
                <path className="cls-2"
                      d="M1909.49,1072.02l-19.65-20.59c-6.34,6.07-13.24,10.87-20.71,14.41-7.47,3.54-15.71,5.31-24.73,5.31-6.9,0-13.28-1.01-19.13-3.03-5.85-2.02-10.92-4.95-15.22-8.78-4.3-3.83-7.68-8.42-10.14-13.76-2.47-5.34-3.7-11.34-3.7-17.99,0-9.97,2.71-18.46,8.14-25.47,5.42-7.01,13.21-12.75,23.35-17.23-4.23-5.64-7.33-10.94-9.3-15.93-1.97-4.99-2.96-10.29-2.96-15.93,0-5.2.98-10.11,2.96-14.74,1.97-4.62,4.79-8.67,8.45-12.14,3.66-3.47,8.1-6.25,13.31-8.34,5.21-2.09,11.13-3.14,17.75-3.14,5.92,0,11.27.94,16.06,2.82,4.79,1.88,8.91,4.48,12.36,7.8,3.45,3.33,6.13,7.23,8.03,11.7,1.9,4.48,2.85,9.32,2.85,14.52,0,10.11-2.96,18.31-8.88,24.6-5.92,6.29-13.81,11.31-23.67,15.06l25.36,26.44c2.82-4.34,5.56-8.96,8.24-13.87,2.67-4.91,5.21-10.04,7.61-15.39l19.65,10.62c-2.96,6.07-6.1,12.03-9.41,17.88-3.31,5.85-6.87,11.45-10.67,16.8l23.25,24.27-19.23,14.09ZM1842.07,1001.15c-6.9,3.03-11.91,6.76-15.01,11.16-3.1,4.41-4.65,9.14-4.65,14.2,0,6.5,2.33,11.85,6.97,16.04,4.65,4.19,10.57,6.28,17.75,6.28,5.07,0,9.93-1.15,14.58-3.47,4.65-2.31,9.09-5.56,13.31-9.75l-32.97-34.46ZM1869.44,940.36c-3.17-3.1-7.22-4.66-12.15-4.66-5.36,0-9.62,1.7-12.79,5.09-3.17,3.4-4.76,7.84-4.76,13.33,0,3.9.85,7.51,2.54,10.84,1.69,3.33,4.58,7.3,8.67,11.92,7.61-2.89,13.38-6.18,17.33-9.86,3.94-3.68,5.92-8.34,5.92-13.98,0-5.34-1.58-9.57-4.75-12.68Z" />
                <path className="cls-1"
                      d="M2069.25,990.75c-3.11,6.5-7.34,11.92-12.71,16.25-5.37,4.33-11.65,7.62-18.85,9.86-7.21,2.24-14.9,3.36-23.09,3.36h-29.45v48.76h-25.78v-151.71h58.33c8.59,0,16.38,1.19,23.35,3.58,6.97,2.38,12.89,5.82,17.75,10.29,4.86,4.48,8.59,9.83,11.2,16.04,2.61,6.21,3.91,13.22,3.91,21.02,0,8.53-1.56,16.04-4.66,22.54ZM2038.8,948.19c-5.8-4.57-13.57-6.85-23.33-6.85h-30.32v54.83h30.32c9.89,0,17.71-2.5,23.43-7.51,5.73-5,8.59-11.64,8.59-19.91,0-9.14-2.9-15.99-8.7-20.56Z" />
                <path className="cls-1" d="M2124.64,917.28v151.71h-25.78v-151.71h25.78Z" />
                <path className="cls-1"
                      d="M2158.66,940.9v-23.62h118.35v19.72l-87.28,108.36h87.28v23.62h-121.1v-19.72l87.28-108.36h-84.54Z" />
                <path className="cls-1"
                      d="M2301.74,940.9v-23.62h118.35v19.72l-87.28,108.36h87.28v23.62h-121.1v-19.72l87.28-108.36h-84.54Z" />
                <path className="cls-1"
                      d="M2591.06,1068.99h-27.47l-15.01-36.63h-69.95l-15.22,36.63h-26.63l65.09-152.79h24.09l65.09,152.79ZM2513.5,948.06l-25.36,60.68h50.93l-25.57-60.68Z" />
              </g>
            </g>
          </g>
        </g>
      </svg>
  );
};

export default DreamCafeLogo;
